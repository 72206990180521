<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2 px-2">
        <b-col
          cols="12"
          md="6"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tableEmployees.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_employment_status"
            >
              <strong>
                Employment Status
              </strong>
            </label>
            <b-select
              id="filter_employment_status"
              v-model="filter.status"
              :options="filterEmploymentStatus"
              :disabled="state.busy"
              @change="onRefreshTableEmployees"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_unit"
            >
              <strong>
                Unit
              </strong>
            </label>
            <b-select
              id="filter_unit"
              v-model="filter.unit"
              :options="filterUnits"
              :disabled="state.busy"
              @change="onRefreshTableEmployees"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="px-1"
        >
          <b-button
            type="button"
            variant="success"
            @click="onShowAddEmployee"
          >
            Add Employee
          </b-button>
        </b-col>

      </b-row>

      <b-row>

        <b-col
          cols="12"
        >
          <b-table
            ref="tableEmployees"
            hover
            responsive
            show-empty
            class="mt-2"
            :per-page="tableEmployees.perPage"
            :current-page="tableEmployees.currentPage"
            :items="myProvider"
            :fields="tableEmployees.fields"
            :sort-by.sync="tableEmployees.sortBy"
            :sort-desc.sync="tableEmployees.sortDesc"
            :sort-direction="tableEmployees.sortDirection"
            :filter="tableEmployees.filter"
            :filter-included-fields="tableEmployees.filterOn"
          >
            <template #cell(index)="row">
              {{ tableEmployees.currentPage * tableEmployees.perPage - tableEmployees.perPage + (row.index + 1) }}
            </template>

            <template #cell(active)="row">
              {{ row.item.active == 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell(employee_code)="row">
              <div class="text-nowrap">
                <strong>{{ row.value }}</strong>
              </div>
            </template>

            <template #cell(email)="row">
              <div class="text-nowrap">
                <strong>{{ row.value }}</strong>
              </div>
            </template>

            <template #cell(position.position_name)="row">
              <div class="text-nowrap">
                <strong>{{ row.value }}</strong>
              </div>
            </template>

            <template #cell(status)="row">
              <div :class="`text-nowrap text-${employmentStatus(row.value)}`">
                <strong>{{ row.value }}</strong>
              </div>
            </template>

            <template #cell()="row">
              <div class="text-nowrap">
                {{ row.value }}
              </div>
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  class="mr-1"
                  :disabled="state.busy"
                  @click="onShowEditEmployee(row.item)"
                >
                  Edit
                </b-button>
                <b-button
                  size="sm"
                  class="mr-1"
                  variant="success"
                  :disabled="state.busy"
                  @click="onShowEmployeeItem(row.item)"
                >
                  Items And Peripherals
                </b-button>
                <b-button
                  v-if="row.item.items.length > 0"
                  size="sm"
                  variant="info"
                  :disabled="state.busy"
                  @click="onPrintContract(row.item)"
                >
                  Contract
                </b-button>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableEmployees.perPage"
              :options="tableEmployees.pageOptions"
              size="sm"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableEmployees.currentPage"
            :total-rows="tableEmployees.totalRows"
            :per-page="tableEmployees.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>

      </b-row>
    </b-card>

    <b-modal
      id="modal-employee"
      size="xs"
      scrollable
      no-close-on-backdrop
      :title="ModalEmployeeTitle"
      @ok="onValidateFormEmployee"
    >
      <ValidationObserver
        ref="formEmployee"
      >
        <form
          id="formEmployee"
          novalidate
          @submit.prevent
        >
          <b-row>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="email"
                vid="email"
                rules="required|email|max:100"
              >
                <b-form-group>
                  <label for="employee_email">
                    <strong>
                      Email
                    </strong>
                  </label>
                  <b-input
                    id="employee_email"
                    v-model="employee.email"
                    type="email"
                    autocomplete="off"
                    placeholder="enter email"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="password"
                vid="password"
                :rules="passwordRule"
              >
                <b-form-group>
                  <label for="employee_password">
                    <strong>
                      Password
                    </strong>
                    <span v-if="state.editing">( <i>Leave blank when not changing password</i> )</span>
                  </label>
                  <b-input
                    id="employee_password"
                    v-model="employee.password"
                    type="password"
                    autocomplete="off"
                    placeholder="enter password"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="password confirmation"
                vid="password_confirmation"
                :rules="passwordConfirmationRule"
              >
                <b-form-group>
                  <label for="employee_password_confirmation">
                    <strong>
                      Password Confirmation
                    </strong>
                    <span v-if="state.editing">( <i>Leave blank when not changing password</i> )</span>
                  </label>
                  <b-input
                    id="employee_password_confirmation"
                    v-model="employee.password_confirmation"
                    type="password"
                    autocomplete="off"
                    placeholder="enter password confirmation"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

          </b-row>

          <b-row>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="unit"
                vid="unit"
                rules="required"
              >
                <b-form-group>
                  <label for="employee_unit">
                    <strong>
                      Unit
                    </strong>
                  </label>
                  <v-select
                    id="employee_unit"
                    v-model="selected.unit"
                    type="text"
                    label="unit_name"
                    placeholder="search department here"
                    :options="list.employee.units"
                    :class="[errors.length > 0 ? 'is-invalid' : null]"
                    :disabled="state.busy || fetching.employee.units"
                    :loading="fetching.employee.units"
                  >
                    <template #option="{ unit_name }">
                      <div class="d-flex flex-column">
                        <strong>{{ unit_name }}</strong>
                      </div>
                    </template>
                    <template #no-options="">
                      no available unit
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="department"
                vid="department"
                rules="required"
              >
                <b-form-group>
                  <label for="employee_department">
                    <strong>
                      Department
                    </strong>
                  </label>
                  <v-select
                    id="employee_department"
                    v-model="selected.department"
                    type="text"
                    label="department_name"
                    placeholder="search department here"
                    :options="list.employee.departments"
                    :class="[errors.length > 0 ? 'is-invalid' : null]"
                    :disabled="state.busy || fetching.employee.departments"
                    :loading="fetching.employee.departments"
                  >
                    <template #option="{ department_name }">
                      <div class="d-flex flex-column">
                        <strong>{{ department_name }}</strong>
                      </div>
                    </template>
                    <template #no-options="">
                      no available department
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="position"
                vid="position"
                rules="required"
              >
                <b-form-group>
                  <label for="employee_position">
                    <strong>
                      Position
                    </strong>
                  </label>
                  <v-select
                    id="employee_position"
                    v-model="selected.position"
                    :options="list.employee.positions"
                    type="text"
                    label="position_name"
                    placeholder="search position here"
                    :class="[errors.length > 0 ? 'is-invalid' : null]"
                    :disabled="state.busy || fetching.employee.positions"
                    :loading="fetching.employee.positions"
                  >
                    <template #option="{ position_name }">
                      <div class="d-flex flex-column">
                        <strong>{{ position_name }}</strong>
                      </div>
                    </template>
                    <template #no-options="">
                      no available position
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="status"
                vid="status"
                rules="required"
              >
                <b-form-group>
                  <label for="employee_status">
                    <strong>
                      Employement Status
                    </strong>
                  </label>
                  <b-form-select
                    id="employee_status"
                    v-model="employee.status"
                    :options="list.status"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- select employment satus --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="employee code"
                vid="employee_code"
                rules="required|max:100"
              >
                <b-form-group>
                  <label for="employee_employee_code">
                    <strong>
                      Employee Code
                    </strong>
                  </label>
                  <b-input
                    id="employee_employee_code"
                    v-model="employee.employee_code"
                    type="text"
                    autocomplete="off"
                    placeholder="enter employee code"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="first name"
                vid="first_name"
                rules="required|max:100"
              >
                <b-form-group>
                  <label for="employee_first_name">
                    <strong>
                      First Name
                    </strong>
                  </label>
                  <b-input
                    id="employee_first_name"
                    v-model="employee.first_name"
                    type="text"
                    autocomplete="off"
                    placeholder="enter first name"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="middle name"
                vid="middle_name"
                rules="max:100"
              >
                <b-form-group>
                  <label for="employee_middle_name">
                    <strong>
                      Middle Name
                    </strong>
                  </label>
                  <b-input
                    id="employee_middle_name"
                    v-model="employee.middle_name"
                    type="text"
                    autocomplete="off"
                    placeholder="enter middle name"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="last name"
                vid="last_name"
                rules="required|max:100"
              >
                <b-form-group>
                  <label for="employee_last_name">
                    <strong>
                      Last Name
                    </strong>
                  </label>
                  <b-input
                    id="employee_last_name"
                    v-model="employee.last_name"
                    type="text"
                    autocomplete="off"
                    placeholder="enter last name"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="suffix name"
                vid="suffix_name"
                rules="max:10"
              >
                <b-form-group>
                  <label for="employee_suffix_name">
                    <strong>
                      Suffix Name
                    </strong>
                  </label>
                  <b-input
                    id="employee_suffix_name"
                    v-model="employee.suffix_name"
                    type="text"
                    autocomplete="off"
                    placeholder="enter suffix name"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="address"
                vid="address"
                rules="max:500"
              >
                <b-form-group>
                  <label for="employee_address">
                    <strong>
                      Address
                    </strong>
                  </label>
                  <b-textarea
                    id="employee_address"
                    v-model="employee.address"
                    rows="6"
                    max-rows="8"
                    placeholder="enter address"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>

            <b-col
              v-if="state.editing"
              cols="12"
            >
              <b-row>

                <b-col
                  cols="12"
                >
                  <div
                    v-if="state.editing"
                    class="form-group"
                  >
                    <b-form-checkbox
                      id="login_local"
                      v-model="employee.login_local"
                      name="login_local"
                      value="1"
                      unchecked-value="0"
                      switch
                      :disabled="state.busy"
                    >
                      Use Login Local?
                    </b-form-checkbox>
                  </div>
                </b-col>

                <b-col
                  cols="12"
                >
                  <div
                    class="form-group"
                  >
                    <b-form-checkbox
                      id="login_sso"
                      v-model="employee.login_sso"
                      name="login_sso"
                      value="1"
                      unchecked-value="0"
                      switch
                      :disabled="state.busy"
                    >
                      Use Login SSO?
                    </b-form-checkbox>
                  </div>
                </b-col>

                <b-col
                  cols="12"
                >
                  <div
                    class="form-group"
                  >
                    <b-form-checkbox
                      id="is_noted_by"
                      v-model="employee.is_noted_by"
                      name="is_noted_by"
                      value="1"
                      unchecked-value="0"
                      switch
                      :disabled="state.busy"
                    >
                      Is Noted By?
                    </b-form-checkbox>
                  </div>
                </b-col>

                <b-col
                  cols="12"
                >
                  <div
                    class="form-group"
                  >
                    <b-form-checkbox
                      id="notifiable"
                      v-model="employee.notifiable"
                      name="notifiable"
                      value="1"
                      unchecked-value="0"
                      switch
                      :disabled="state.busy"
                    >
                      Is Notifiable?
                    </b-form-checkbox>
                  </div>
                </b-col>

              </b-row>
            </b-col>

            <b-col
              cols="12"
            >
              <div
                v-if="state.editing"
                class="form-group"
              >
                <b-form-checkbox
                  id="is_active"
                  v-model="employee.active"
                  name="is_active"
                  value="1"
                  unchecked-value="0"
                  switch
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </div>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <b-modal
      id="modal-employee-items"
      size="xl"
      scrollable
      no-close-on-backdrop
      title="Employee Items and Peripherals"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label for="items_employee_code">
                  <strong>
                    Employee Code
                  </strong>
                </label>
                <b-input
                  id="items_employee_code"
                  v-model="employee.employee_code"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="8"
            >
              <b-form-group>
                <label for="items_email">
                  <strong>
                    Email
                  </strong>
                </label>
                <b-input
                  id="items_email"
                  v-model="employee.email"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="items_employee_name">
                  <strong>
                    Name
                  </strong>
                </label>
                <b-input
                  id="items_employee_name"
                  v-model="employee.employee_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="items_unit_name">
                  <strong>
                    Unit
                  </strong>
                </label>
                <b-input
                  id="items_unit_name"
                  v-model="employee.unit_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="items_department_name">
                  <strong>
                    Department
                  </strong>
                </label>
                <b-input
                  id="items_department_name"
                  v-model="employee.department_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="items_position_name">
                  <strong>
                    Position
                  </strong>
                </label>
                <b-input
                  id="items_position_name"
                  v-model="employee.position_name"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group>
                <label for="items_employee_status">
                  <strong>
                    Employment Status
                  </strong>
                </label>
                <b-input
                  id="items_employee_status"
                  v-model="employee.status"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="items_address">
                  <strong>
                    Address
                  </strong>
                </label>
                <b-textarea
                  id="items_address"
                  v-model="employee.address"
                  rows="6"
                  max-rows="8"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <b-tabs>
            <b-tab
              title="Items"
              class="scrollable-tab"
            >
              <b-row class="px-2 py-2">
                <b-col
                  cols="12"
                  class="mb-1"
                >
                  <b-button
                    class="mr-1"
                    variant="success"
                    :disabled="state.busy"
                    @click="onShowAssignItem"
                  >
                    Assign New Item
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-table
                    ref="tableItems"
                    hover
                    responsive
                    show-empty
                    class="mt-2"
                    :items="itemsProvider"
                    :per-page="tableItems.perPage"
                    :current-page="tableItems.currentPage"
                    :fields="tableItems.fields"
                    :sort-by.sync="tableItems.sortBy"
                    :sort-desc.sync="tableItems.sortDesc"
                    :sort-direction="tableItems.sortDirection"
                    :filter="tableItems.filter"
                    :filter-included-fields="tableItems.filterOn"
                  >
                    <template #cell(action)="row">
                      <div
                        class="text-nowrap"
                      >
                        <b-button
                          variant="danger"
                          class="text-white mr-1"
                          :disabled="state.busy"
                          @click="onShowReturnAssignedItem(row.item)"
                        >
                          Return
                        </b-button>
                        <b-button
                          v-if="row.item.issuance_id === null"
                          variant="warning"
                          class="text-white"
                          :disabled="state.busy"
                          @click="onShowIssueAssignedItem(row.item)"
                        >
                          Issue
                        </b-button>
                      </div>
                    </template>

                    <template #cell()="row">
                      <div class="text-nowrap">
                        {{ row.value }}
                      </div>
                    </template>

                    <template #cell(item_code)="row">
                      <div class="text-nowrap">
                        <strong>{{ row.value }}</strong>
                      </div>
                    </template>

                    <template #cell(category_name)="row">
                      <div class="text-nowrap">
                        <strong class="text-success">{{ row.value }}</strong>
                      </div>
                    </template>

                    <template #cell(serial_number)="row">
                      <div class="text-nowrap">
                        <strong>{{ row.value }}</strong>
                      </div>
                    </template>

                  </b-table>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                    <b-select
                      v-model="tableItems.perPage"
                      :options="tableItems.pageOptions"
                      size="sm"
                    />
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="tableItems.currentPage"
                    :total-rows="tableItems.totalRows"
                    :per-page="tableItems.perPage"
                    first-number
                    last-number
                    pills
                    prev-text="Prev"
                    next-text="Next"
                    aria-controls="table"
                  />
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              title="Peripherals"
              class="scrollable-tab"
            >
              <b-row class="px-2 py-2">
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label
                      for="filter_search_item_details"
                    >
                      <strong>
                        Search
                      </strong>
                    </label>
                    <b-input
                      id="filter_search_item_details"
                      v-model="tableItemDetails.filter"
                      type="text"
                      placeholder="search here"
                      debounce="1000"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-table
                    ref="tableItemDetails"
                    hover
                    responsive
                    show-empty
                    class="mt-2"
                    :items="itemDetailsProvider"
                    :fields="tableItemDetails.fields"
                    :per-page="tableItemDetails.perPage"
                    :current-page="tableItemDetails.currentPage"
                    :sort-by.sync="tableItemDetails.sortBy"
                    :sort-desc.sync="tableItemDetails.sortDesc"
                    :sort-direction="tableItemDetails.sortDirection"
                    :filter="tableItemDetails.filter"
                    :filter-included-fields="tableItemDetails.filterOn"
                  >
                    <template #cell(action)="row">
                      <div
                        class="form-group"
                      >
                        <b-button
                          variant="warning"
                          class="text-white"
                          :disabled="row"
                        >
                          Issue
                        </b-button>
                      </div>
                    </template>

                    <template #cell(item_detail_code)="row">
                      <div class="text-nowrap">
                        <strong>{{ row.value }}</strong>
                      </div>
                    </template>

                    <template #cell()="row">
                      <div class="text-nowrap">
                        {{ row.value }}
                      </div>
                    </template>

                    <template #cell(category_name)="row">
                      <div class="text-nowrap">
                        <strong class="text-success">{{ row.value }}</strong>
                      </div>
                    </template>

                    <template #cell(serial_number)="row">
                      <div class="text-nowrap">
                        <strong>{{ row.value }}</strong>
                      </div>
                    </template>

                  </b-table>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                    <b-select
                      v-model="tableItemDetails.perPage"
                      :options="tableItemDetails.pageOptions"
                      size="sm"
                    />
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="tableItemDetails.currentPage"
                    :total-rows="tableItemDetails.totalRows"
                    :per-page="tableItemDetails.perPage"
                    first-number
                    last-number
                    pills
                    prev-text="Prev"
                    next-text="Next"
                    aria-controls="table"
                  />
                </b-col>

              </b-row>
            </b-tab>

            <b-tab
              title="Issuances"
              class="scrollable-tab"
            >
              <b-row class="px-2 py-2">
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label
                      for="filter_search_issuance"
                    >
                      <strong>
                        Search
                      </strong>
                    </label>
                    <b-input
                      id="filter_search_issuance"
                      v-model="tableIssuances.filter"
                      type="text"
                      placeholder="search here"
                      debounce="1000"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-button
                    class="mr-1"
                    variant="success"
                    :disabled="state.busy || issuances.length <= 0"
                    @click="onPrintAllIssuances"
                  >
                    Print All Selected
                  </b-button>
                  <b-button
                    v-if="issuances.length <= 0"
                    variant="success"
                    :disabled="state.busy"
                    @click="onSelectAllIssuances"
                  >
                    Select All
                  </b-button>
                  <b-button
                    v-else
                    variant="danger"
                    :disabled="state.busy"
                    @click="onUnselectAllIssuances"
                  >
                    Unselect All
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-table
                    ref="tableIssuances"
                    hover
                    responsive
                    show-empty
                    class="mt-2"
                    selectable
                    :select-mode="tableIssuances.selectMode"
                    :items="issuancesProvider"
                    :fields="tableIssuances.fields"
                    :per-page="tableIssuances.perPage"
                    :current-page="tableIssuances.currentPage"
                    :sort-by.sync="tableIssuances.sortBy"
                    :sort-desc.sync="tableIssuances.sortDesc"
                    :sort-direction="tableIssuances.sortDirection"
                    :filter="tableIssuances.filter"
                    :filter-included-fields="tableIssuances.filterOn"
                    @row-selected="onSelectIssuance"
                  >

                    <template #cell(action)="row">
                      <div
                        class="text-nowrap"
                      >
                        <b-button
                          @click="onPrintIssuance(row.item)"
                        >
                          Print
                        </b-button>
                      </div>
                    </template>

                    <template #cell()="row">
                      <div class="text-nowrap">
                        {{ row.value }}
                      </div>
                    </template>

                    <template #head(issuance_number)="row">
                      <div class="text-nowrap text-center">
                        {{ row.label }}
                      </div>
                    </template>

                    <template #cell(issuance_number)="row">
                      <div class="text-nowrap text-center">
                        <strong>{{ row.value }}</strong>
                      </div>
                    </template>

                    <template #cell(selected)="{ rowSelected }">
                      <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                      </template>
                      <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not Selected</span>
                      </template>
                    </template>

                  </b-table>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                    <b-select
                      v-model="tableIssuances.perPage"
                      :options="tableIssuances.pageOptions"
                      size="sm"
                    />
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="tableIssuances.currentPage"
                    :total-rows="tableIssuances.totalRows"
                    :per-page="tableIssuances.perPage"
                    first-number
                    last-number
                    pills
                    prev-text="Prev"
                    next-text="Next"
                    aria-controls="table"
                  />
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-employee-item-details"
      size="xl"
      scrollable
      no-close-on-backdrop
      :title="ModalAssignItemDetailsTitle"
      @shown="selectAllIncludedPeripherals"
    >
      <div>
        <ValidationObserver
          ref="formAssignEmployeeItem"
        >
          <form
            novalidate
            @submit.prevent
          >
            <b-row>

              <b-col
                cols="12"
                md="4"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="template"
                  vid="template"
                  rules="required"
                >
                  <b-form-group>
                    <label for="assign_template">
                      <strong>
                        Template
                      </strong>
                    </label>
                    <b-form-select
                      v-if="state.action_status === 'ASSIGN'"
                      id="assign_template"
                      v-model="item.template"
                      :options="list.assign.templates"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy || fetching.assign.templates || state.issuing || state.returning"
                      @change="getAssignItems"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- select template --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-input
                      v-else
                      id="assign_template"
                      v-model="item.template_name"
                      :state="errors.length > 0 ? false : null"
                      disabled
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >
                <b-form-group>
                  <label for="assign_category_name">
                    <strong>
                      Category
                    </strong>
                  </label>
                  <b-input
                    id="assign_category_name"
                    v-model="item.category_name"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >
                <b-form-group>
                  <label for="assign_item_code">
                    <strong>
                      Item Code
                    </strong>
                  </label>
                  <b-input
                    id="assign_item_code"
                    v-model="item.item_code"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="serial number"
                  vid="item_serial_number"
                  rules="required"
                >
                  <b-form-group>
                    <label for="assign_serial_number">
                      <strong>
                        Serial Number
                      </strong>
                    </label>
                    <v-select
                      v-if="state.action_status === 'ASSIGN'"
                      id="assign_serial_number"
                      v-model="selected.item"
                      type="text"
                      label="serial_number"
                      placeholder="search serial number here"
                      :options="filterEmployeeItems"
                      :class="[errors.length > 0 ? 'is-invalid' : null]"
                      :disabled="state.busy || fetching.assign.items || state.issuing || state.returning"
                      :loading="fetching.assign.items"
                    >
                      <template #option="{ serial_number, item_name }">
                        <div class="d-flex flex-column">
                          <strong>{{ serial_number }}</strong>
                          <span>{{ item_name }}</span>
                        </div>
                      </template>
                      <template #no-options="">
                        no available item
                      </template>
                    </v-select>
                    <b-input
                      v-else
                      id="assign_serial_number"
                      v-model="item.serial_number"
                      :state="errors.length > 0 ? false : null"
                      disabled
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="8"
              >
                <b-form-group>
                  <label for="assign_item_name">
                    <strong>
                      Name
                    </strong>
                  </label>
                  <b-input
                    id="assign_item_name"
                    v-model="item.item_name"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="status"
                  vid="status"
                  rules="required"
                >
                  <b-form-group>
                    <label for="assign_status">
                      <strong>
                        Status
                      </strong>
                    </label>
                    <b-form-select
                      id="assign_status"
                      v-model="item.status"
                      :options="list.assign.statuses"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy || fetching.assign.statuses"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- select status --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >
                <b-form-group>
                  <label for="assign_last_status_cost">
                    <strong>
                      Last Status Cost
                    </strong>
                  </label>
                  <b-input
                    id="assign_last_status_cost"
                    v-model="item.status_last_cost"
                    type="number"
                    step="0.01"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col
                v-if="item.costable"
                cols="12"
                md="4"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="status cost"
                  vid="status_cost"
                  rules="required"
                >
                  <b-form-group>
                    <label for="assign_status_cost">
                      <strong>
                        Status Cost
                      </strong>
                    </label>
                    <b-input
                      id="assign_status_cost"
                      v-model="item.status_cost"
                      type="number"
                      placeholder="enter cost"
                      autocomplete="off"
                      step="0.01"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >
                <b-form-group>
                  <label for="assign_supplier_name">
                    <strong>
                      Supplier
                    </strong>
                  </label>
                  <b-input
                    id="assign_supplier_name"
                    v-model="item.supplier_name"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="4"
              >
                <b-form-group>
                  <label for="assign_brand_name">
                    <strong>
                      Brand
                    </strong>
                  </label>
                  <b-input
                    id="assign_brand_name"
                    v-model="item.brand_name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-form-group>
                  <label for="assign_specifications">
                    <strong>
                      Specifications
                    </strong>
                  </label>
                  <b-textarea
                    id="assign_specifications"
                    v-model="item.specifications"
                    rows="8"
                    max-rows="12"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
              >
                <b-table
                  ref="tableItemDetailsOfItems"
                  hover
                  responsive
                  show-empty
                  class="mt-2"
                  selectable
                  :select-mode="tableItemDetailsOfItems.selectMode"
                  :items="item.item_details"
                  :fields="tableItemDetailsOfItems.fields"
                  @row-selected="onAssignItemDetail"
                >
                  <template #head(selected)="row">
                    <div
                      v-if="row"
                      class="text-center"
                    >
                      SELECTED
                    </div>
                  </template>

                  <template #cell(selected)="row">
                    <div class="d-flex justify-content-center align-self-center mt-2">
                      <template v-if="row.rowSelected">
                        <span aria-hidden="true">
                          &check;
                        </span>
                        <span class="sr-only">Selected</span>
                      </template>
                      <template v-else>
                        <span aria-hidden="true">
                          &nbsp;
                        </span>
                        <span class="sr-only">Not selected</span>
                      </template>
                    </div>
                  </template>

                  <template #cell(item_detail_code)="row">
                    <div class="text-nowrap">
                      <strong>{{ row.value }}</strong>
                    </div>
                  </template>

                  <template #cell()="row">
                    <div class="text-nowrap">
                      {{ row.value }}
                    </div>
                  </template>

                  <template #cell(category_name)="row">
                    <div class="text-nowrap">
                      <strong class="text-success">{{ row.value }}</strong>
                    </div>
                  </template>

                  <template #cell(serial_number)="row">
                    <div class="text-nowrap">
                      <strong>{{ row.value }}</strong>
                    </div>
                  </template>

                </b-table>
              </b-col>
            </b-row>
          </form>
        </ValidationObserver>
      </div>
      <template #modal-footer="{ cancel }">
        <div class="col-12 d-flex justify-content-between">
          <div>
            <b-button
              v-if="state.action_status === 'ASSIGN'"
              variant="success"
              :disabled="state.busy"
              @click="onValidateAssignEmployeeItem"
            >
              Assign
            </b-button>
            <b-button
              v-if="state.action_status === 'ISSUE'"
              class="mr-2"
              variant="success"
              :disabled="state.busy"
              @click="onValidateIssueAssignedEmployeeItem"
            >
              Issue
            </b-button>
            <b-button
              v-if="['ISSUE', 'RETURN'].includes(state.action_status)"
              variant="danger"
              :disabled="state.busy"
              @click="onValidateReturnAssignedEmployeeItem"
            >
              Return
            </b-button>
          </div>
          <div>
            <b-button
              variant="danger"
              :disabled="state.busy"
              @click="cancel()"
            >
              Close Window
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modal-contract-print"
      no-close-on-backdrop
      scrollable
      size="xl"
    >
      <div style="height: 350%;">
        <iframe
          style="height: 100%; width: 100%"
          :src="contractPDFData"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-issuance-print"
      no-close-on-backdrop
      scrollable
      size="xl"
    >
      <div style="height: 350%;">
        <iframe
          style="height: 100%; width: 100%"
          :src="issuancePDFData"
        />
      </div>
      <template #modal-footer="{ cancel }">
        <div class="col-12 d-flex justify-content-between">
          <div>
            <b-button
              v-if="issuanceWithCost"
              variant="danger"
              :disabled="state.busy"
              @click="$event => toggleIssuanceCost(false)"
            >
              Without Cost
            </b-button>
            <b-button
              v-else
              variant="success"
              :disabled="state.busy"
              @click="$event => toggleIssuanceCost(true)"
            >
              With Cost
            </b-button>
          </div>
          <b-button
            variant="danger"
            :disabled="state.busy"
            @click="cancel()"
          >
            Close Window
          </b-button>
        </div>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AdminEmployeeService, SharedListService, SharedAttachmentService } from '@/services'
import formatter from '@/mixins/formatter'
import contractPDF from '@/mixins/contract-pdf'
import issuancePDF from '@/mixins/issuance-pdf'
import issuancePDFAll from '@/mixins/issuance-pdf-all'

export default {
  name: 'AdminEmployees',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Employees'
    }
  },

  mixins: [formatter, contractPDF, issuancePDF, issuancePDFAll],

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        assigning: false,
        action_status: 'ASSIGN' // ASSIGN, RETURN, ISSUE
      },
      companyLogo: null,
      contractPDFData: null,
      issuancePDFData: null,
      fetching: {
        filter: {
          units: false
        },
        employee: {
          units: false,
          departments: false,
          positions: false
        },
        assign: {
          templates: false,
          items: false,
          item_details: false,
          statuses: false
        }
      },
      filter: {
        unit: 'All',
        status: 'All'
      },
      selected: {
        test: [],
        unit: null,
        department: null,
        position: null,
        item: null,
        item_detail: null,
        item_details: [],
        employee_item_details: [],
        issuance: null
      },
      list: {
        notedByEmployees: [],
        filter: {
          units: [],
          departments: []
        },
        employee: {
          units: [],
          departments: [],
          positions: []
        },
        assign: {
          templates: [],
          items: [],
          item_details: [],
          statuses: []
        },
        status: [
          {
            text: 'Trainee',
            value: 'Trainee'
          },
          {
            text: 'Probationary',
            value: 'Probationary'
          },
          {
            text: 'Regular',
            value: 'Regular'
          },
          {
            text: 'Resigned',
            value: 'Resigned'
          }
        ]
      },
      employee: {
        id: null,
        employee_code: null,
        email: null,
        password: null,
        password_confirmation: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        suffix_name: null,
        employee_name: null,
        address: null,
        unit: null,
        unit_name: null,
        department: null,
        department_name: null,
        position: null,
        position_name: null,
        status: null,
        login_local: 0,
        login_sso: 1,
        is_noted_by: 0,
        notifiable: 0,
        active: 1,
        items: [],
        item_details: []
      },
      item: {
        id: null,
        template: null,
        item_code: null,
        item_name: null,
        serial_number: null,
        type_name: null,
        category_name: null,
        supplier_name: null,
        brand_name: null,
        status: null,
        status_name: null,
        status_last_cost: 0.00,
        status_cost: 0.00,
        specifications: null,
        item_details: [],
        costable: 0
      },
      item_detail: {
        id: null,
        item: null,
        item_code: null,
        item_name: null,
        item_detail_code: null,
        item_detail_name: null,
        serial_number: null,
        type_name: null,
        category_name: null,
        supplier_name: null,
        brand_name: null,
        status: null,
        status_name: null,
        status_last_cost: 0.00,
        status_cost: 0.00,
        costable: 0
      },
      issuances: [],
      issuanceWithCost: true,
      tableEmployees: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'actions' },
          { key: 'employee_code', class: 'text-center', sortable: true },
          { key: 'first_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'email' },
          { key: 'department.department_name', label: 'department' },
          { key: 'position.position_name', label: 'position' },
          { key: 'status', class: 'text-center' },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ]
      },
      tableItems: {
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'action' },
          { key: 'item_code' },
          { key: 'category_name', label: 'category' },
          { key: 'serial_number' },
          { key: 'status_name', label: 'status' }
        ]
      },
      tableItemDetails: {
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'item.item_name', label: 'parent name' },
          { key: 'item.serial_number', label: 'parent serial number' },
          { key: 'item_detail_code' },
          { key: 'category_name', label: 'category' },
          { key: 'serial_number' },
          { key: 'status_name', label: 'status' }
        ]
      },
      tableItemDetailsOfItems: {
        selectMode: 'multi',
        fields: [
          { key: 'selected' },
          { key: 'employee_name', label: 'employee' },
          { key: 'item_detail_code' },
          { key: 'category_name', label: 'category' },
          { key: 'serial_number' },
          { key: 'status_name', label: 'status' }
        ]
      },
      tableIssuances: {
        selectMode: 'multi',
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'action' },
          { key: 'selected' },
          { key: 'issuance_number', sortable: true },
          { key: 'created_at', formatter: this.dateTimeShortFormatter, label: 'Date Issued', sortable: true },
          { key: 'issued_by_name', label: 'Issuer' }
        ]
      }
    }
  },

  computed: {
    ModalEmployeeTitle () {
      return this.state.editing ? 'Edit Employee' : 'Add Employee'
    },

    ModalAssignItemDetailsTitle () {
      switch (this.state.action_status) {
        case 'ISSUE': {
          return 'Issue Items and Its Peripherals'
        }
        case 'RETURN': {
          return 'Return Items and Its Peripherals'
        }
        default: {
          return 'Assign Items and Its Peripherals'
        }
      }
    },

    passwordRule () {
      return `${this.state.editing ? '' : 'required|'}min:8|confirmed:password_confirmation`
    },

    passwordConfirmationRule () {
      return `${this.state.editing ? '' : 'required'}`
    },

    filterEmploymentStatus () {
      return [{ text: 'All', value: 'All' }].concat(this.list.status)
    },

    filterUnits () {
      return [{ text: 'All', value: 'All' }].concat(this.list.filter.units)
    },

    filterEmployeeItems () {
      return this.list.assign.items
    },

    filterEmployeeItemDetails () {
      return this.list.employee.item_details
    }
  },

  watch: {

    'selected.unit' (unit) {
      if (unit?.id) {
        this.employee.unit = unit.id
      } else {
        this.employee.unit = null
        this.selected.department = null
        this.selected.position = null
        this.list.employee.departments = []
        this.list.employee.positions = []
      }
    },

    'selected.department' (department) {
      this.employee.department = department?.id || null
    },

    'selected.position' (position) {
      this.employee.position = position?.id || null
    },

    'selected.item' (item) {
      this.item.costable = 0

      if (item?.id) {
        this.item.id = item.id
        this.item.template = item.template_id
        this.item.template_name = item.template_name
        this.item.item_code = item.item_code
        this.item.item_name = item.item_name
        this.item.serial_number = item.serial_number
        this.item.type_name = item.type_name
        this.item.category_name = item.category_name
        this.item.supplier_name = item.supplier_name
        this.item.brand_name = item.brand_name
        this.item.status = item.status_id
        this.item.status_name = item.status_name
        this.item.status_last_cost = item.status_cost
        this.item.status_cost = 0.00
        this.item.specifications = item.specifications
        this.item.item_details = item.item_details
      } else {
        this.item.id = null
        this.item.item_code = null
        this.item.item_name = null
        this.item.serial_number = null
        this.item.type_name = null
        this.item.category_name = null
        this.item.supplier_name = null
        this.item.brand_name = null
        this.item.status = null
        this.item.status_name = null
        this.item.status_last_cost = 0.00
        this.item.status_cost = 0.00
        this.item.specifications = null
        this.item.item_details = []
      }
    },

    'item.item_details' (itemDetails) {
      if (itemDetails.length > 0) {
        this.selectAllIncludedPeripherals()
      }
    },

    'item.status' (itemStatus) {
      if (itemStatus) {
        const index = this.list.assign.statuses.findIndex(status => status.value === itemStatus)
        if (index >= 0) {
          this.item.costable = this.list.assign.statuses[index].costable
        }
      }
    },

    'selected.item_detail' (itemDetail) {
      this.item_detail.costable = 0
      if (itemDetail) {
        this.item_detail.id = itemDetail.id
        this.item_detail.item = itemDetail.item_id
        this.item_detail.item_detail_code = itemDetail.item_detail_code
        this.item_detail.item_detail_name = itemDetail.item_detail_name
        this.item_detail.serial_number = itemDetail.serial_number
        this.item_detail.type_name = itemDetail.type_name
        this.item_detail.category_name = itemDetail.category_name
        this.item_detail.supplier_name = itemDetail.supplier_name
        this.item_detail.brand_name = itemDetail.brand_name
        this.item_detail.status = itemDetail.status_id
        this.item_detail.status_name = itemDetail.status_name
        this.item_detail.status_last_cost = itemDetail.status_cost
        this.item_detail.status_cost = 0.00
        this.item_detail.specifications = itemDetail.specifications
      } else {
        this.item_detail.id = null
        this.item_detail.item = null
        this.item_detail.item_detail_code = null
        this.item_detail.item_detail_name = null
        this.item_detail.serial_number = null
        this.item_detail.type_name = null
        this.item_detail.category_name = null
        this.item_detail.supplier_name = null
        this.item_detail.brand_name = null
        this.item_detail.status = null
        this.item_detail.status_name = null
        this.item_detail.status_last_cost = 0.00
        this.item_detail.status_cost = 0.00
        this.item_detail.specifications = null
      }
    },

    'filter.unit' () {
      this.onRefreshTableEmployees()
    },

    'employee.unit' (unit) {
      if (!this.fetching.unit && unit) {
        this.onSelectEmployeeUnit(unit)
      }
    },

    'item_detail.status' (itemDetail) {
      const status = this.list.employee.statuses.find(status => status.value === itemDetail)
      if (status) {
        this.item_detail.costable = status.costable
      } else {
        this.item_detail.costable = false
      }
    }

  },

  mounted () {
    core.index()

    this.getCompanyLogo().then(() => {
      this.getFilterUnits()
    })
  },

  methods: {

    toggleIssuanceCost (withCost = false) {
      this.issuanceWithCost = withCost

      if (this.selected.issuance) {
        this.onPrintIssuance(this.selected.issuance)
      } else {
        this.onPrintAllIssuances()
      }
    },

    async myProvider (ctx) {
      return await AdminEmployeeService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_unit: this.filter.unit,
          filter_status: this.filter.status
        })
      ).then(({ data }) => {
        this.tableEmployees.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    onRefreshTableEmployees () {
      this.$refs.tableEmployees.refresh()
    },

    async getCompanyLogo () {
      return new Promise(resolve => {
        SharedAttachmentService.retrieveCompanyLogo().then(({ data }) => {
          this.companyLogo = data
          resolve(true)
        })
      })
    },

    async getFilterUnits () {
      this.fetching.filter.units = true
      return new Promise(resolve => {
        SharedListService.getUnits().then(({ data }) => {
          this.list.filter.units = data.map(({ id, unit_name }) => ({ value: id, text: unit_name }))
          this.fetching.filter.units = false
          resolve(true)
        })
      })
    },

    async getEmployeeUnits () {
      this.fetching.employee.units = true
      return new Promise(resolve => {
        SharedListService.getUnits().then(({ data }) => {
          this.list.employee.units = data.map(({ id, unit_name }) => ({ id, unit_name }))
          this.fetching.employee.units = false
          resolve(true)
        })
      })
    },

    async getEmployeeDepartments (unit) {
      this.fetching.employee.departments = this.state.busy = true
      this.list.employee.departments = []
      this.selected.department = null
      return new Promise(resolve => {
        SharedListService.getDepartments(`unit=${unit}`).then(({ data }) => {
          this.list.employee.departments = data.map(({ id, department_name }) => ({ id, department_name }))
          this.fetching.employee.departments = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getEmployeePositions (unit) {
      this.fetching.employee.positions = this.state.busy = true
      this.list.employee.positions = []
      this.selected.position = null
      return new Promise(resolve => {
        SharedListService.getPositions(`unit=${unit}`).then(({ data }) => {
          this.list.employee.positions = data.map(({ id, position_name }) => ({ id, position_name }))
          this.fetching.employee.positions = this.state.busy = false
          resolve(true)
        })
      })
    },

    async onSelectEmployeeUnit (unit, callbackDepartment = null, callbackPosition = null) {
      this.getEmployeeDepartments(unit).then(() => {
        if (typeof callbackDepartment === 'function') {
          callbackDepartment()
        }
      })

      this.getEmployeePositions(unit).then(() => {
        if (typeof callbackPosition === 'function') {
          callbackPosition()
        }
      })
    },

    async onShowAddEmployee () {
      this.state.editing = false

      this.employee.id = null
      this.employee.employee_code = null
      this.employee.first_name = null
      this.employee.middle_name = null
      this.employee.last_name = null
      this.employee.suffix_name = null
      this.employee.email = null
      this.employee.address = null
      this.employee.unit = null
      this.employee.department = null
      this.employee.position = null
      this.employee.status = null
      this.employee.active = 1
      this.employee.login_local = 1
      this.employee.login_sso = 0
      this.employee.is_noted_by = 0
      this.employee.notifiable = 0

      this.selected.unit = null
      this.selected.department = null
      this.selected.position = null

      this.getEmployeeUnits()

      this.$bvModal.show('modal-employee')
    },

    async onShowEditEmployee (employee) {
      this.state.editing = true

      this.employee.id = employee.id
      this.employee.employee_code = employee.employee_code
      this.employee.first_name = employee.first_name
      this.employee.middle_name = employee.middle_name
      this.employee.last_name = employee.last_name
      this.employee.suffix_name = employee.suffix_name
      this.employee.email = employee.email
      this.employee.address = employee.address
      this.employee.status = employee.status
      this.employee.active = employee.active

      this.employee.unit = employee.unit.id
      this.employee.department = employee.department.id

      this.employee.login_local = employee.login_local
      this.employee.login_sso = employee.login_sso
      this.employee.is_noted_by = employee.is_noted_by
      this.employee.notifiable = employee.notifiable

      this.getEmployeeUnits().then(() => {
        this.selected.unit = {
          id: employee.unit.id,
          unit_name: employee.unit.unit_name
        }

        this.onSelectEmployeeUnit(
          employee.unit.id,
          () => {
            this.selected.department = {
              id: employee.department.id,
              department_name: employee.department.department_name
            }
          },
          () => {
            this.selected.position = {
              id: employee.position.id,
              position_name: employee.position.position_name
            }
          }
        )
      })

      this.$bvModal.show('modal-employee')
    },

    async onValidateFormEmployee (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formEmployee.validate().then(async success => {
        if (success) {
          let text = 'Do you really want to create employee?'
          if (this.state.editing) {
            text = 'Do you really want to save changes?'
          }
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.onPutEmployee()
              } else {
                return this.onPostEmployee()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onPostEmployee () {
      return new Promise(resolve => {
        AdminEmployeeService.post(this.employee).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-employee')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableEmployees.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formEmployee.setErrors(error.message)
          resolve(error)
        })
      })
    },

    async onPutEmployee () {
      return new Promise(resolve => {
        AdminEmployeeService.put(this.employee).then(({ data }) => {
          this.$bvModal.hide('modal-employee')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableEmployees.localItems, { id: data.employee.id })
            row.active = data.employee.active
            row.employee_code = data.employee.employee_code
            row.email = data.employee.email
            row.first_name = data.employee.first_name
            row.middle_name = data.employee.middle_name
            row.last_name = data.employee.last_name
            row.suffix_name = data.employee.suffix_name
            row.unit = data.employee.unit
            row.unit_id = data.employee.unit_id
            row.department = data.employee.department
            row.department_id = data.employee.department_id
            row.position = data.employee.position
            row.position_id = data.employee.position_id
            row.address = data.employee.address
            row.login_local = data.employee.login_local
            row.login_sso = data.employee.login_sso
            row.is_noted_by = data.employee.is_noted_by
            row.notifiable = data.employee.notifiable
            row.status = data.employee.status
            row.updated_at = data.employee.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formEmployee.setErrors(error.message)
          resolve(error)
        })
      })
    },

    async getNotedByEmployees () {
      return await SharedListService.getEmployees('noted_by=1').then(({ data }) => {
        this.list.notedByEmployees = data
      })
    },

    async onPrintContract (employee) {
      this.state.busy = true
      const pdfDocument = this.createContractPDFOptions({
        info: {
          title: 'Memorandum of Agreement in the Issuance of Laptop',
          author: '',
          creator: '',
          subject: ''
        },
        employee: {
          id: employee.id,
          employee_code: employee.employee_code,
          first_name: employee.first_name,
          middle_name: employee.middle_name,
          last_name: employee.last_name,
          unit: employee.unit.unit_name,
          department: employee.department.department_name,
          position: employee.position.position_name,
          employment_status: employee.status,
          address: employee.address,
          items: employee.items,
          item_details: employee.item_details
        },
        logo: this.companyLogo
      })

      pdfDocument.getDataUrl(encodedPDFData => {
        this.contractPDFData = encodedPDFData
        this.state.busy = false
      })

      this.$bvModal.show('modal-contract-print')
    },

    // ITEMS
    async itemsProvider (ctx) {
      return await AdminEmployeeService.getItems(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_employee: this.employee.id
        })
      ).then(({ data }) => {
        this.tableItems.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    async getAssignItemStatuses () {
      this.fetching.assign.statuses = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getStatuses().then(({ data }) => {
          this.list.assign.statuses = data.map(({ id, status_name, costable }) => ({
            text: status_name,
            value: id,
            costable: costable
          }))
          this.fetching.assign.statuses = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getAssignTemplates () {
      this.fetching.assign.templates = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getTemplates('with_item_count=1').then(({ data }) => {
          this.list.assign.templates = data.filter(template => template.items_count > 0).map(({ id, template_name }) => ({ value: id, text: template_name }))
          this.fetching.assign.templates = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getAssignItems (template) {
      this.fetching.assign.items = this.state.busy = true
      this.selected.item = null
      return new Promise(resolve => {
        SharedListService.getAssignableItems(`template=${template}`).then(({ data }) => {
          this.list.assign.items = data
          this.fetching.assign.items = this.state.busy = false
          resolve(true)
        })
      })
    },

    // async getEmployeeItemDetails () {
    //   this.fetching.employee.item_details = this.state.busy = true
    //   return new Promise(resolve => {
    //     SharedListService.getAssignableItemDetails().then(({ data }) => {
    //       this.list.employee.item_details = data
    //       this.fetching.employee.item_details = this.state.busy = false
    //       resolve(true)
    //     })
    //   })
    // },

    onShowEmployeeItem (employee) {
      this.state.assigning = true

      this.employee.id = employee.id
      this.employee.employee_code = employee.employee_code
      this.employee.first_name = employee.first_name
      this.employee.middle_name = employee.middle_name
      this.employee.last_name = employee.last_name
      this.employee.suffix_name = employee.suffix_name
      this.employee.employee_name = `${employee.first_name} ${employee.middle_name} ${employee.last_name} ${employee?.suffix_name || ''}`
      this.employee.email = employee.email
      this.employee.unit_name = employee.unit_name
      this.employee.department_name = employee.department_name
      this.employee.position_name = employee.position_name
      this.employee.address = employee.address
      this.employee.status = employee.status

      this.employee.items = employee.items
      this.employee.item_details = employee.item_details // assigned

      this.issuances = []
      this.selected.issuance = null
      this.issuanceWithCost = true

      this.getAssignTemplates().then(() => {
        this.$bvModal.show('modal-employee-items')
      })
    },

    onShowAssignItem () {
      this.state.action_status = 'ASSIGN'

      this.item.id = null
      this.item.template = null
      this.item.template_name = null
      this.item.item_code = null
      this.item.item_name = null
      this.item.serial_number = null
      this.item.type_name = null
      this.item.category_name = null
      this.item.supplier_name = null
      this.item.brand_name = null
      this.item.status = null
      this.item.status_name = null
      this.item.status_last_cost = 0.00
      this.item.status_cost = 0.00
      this.item.specifications = null
      this.item.item_details = []
      this.item.costable = 0

      this.selected.item = null
      this.selected.item_details = []

      this.getAssignItemStatuses().then(() => {
        this.$bvModal.show('modal-employee-item-details')
      })
    },

    onShowIssueAssignedItem (item) {
      this.state.action_status = 'ISSUE'

      this.item.id = item.id
      this.item.template = item.template_id
      this.item.template_name = item.template_name
      this.item.item_code = item.item_code
      this.item.item_name = item.item_name
      this.item.serial_number = item.serial_number
      this.item.type_name = item.type_name
      this.item.category_name = item.category_name
      this.item.supplier_name = item.supplier_name
      this.item.brand_name = item.brand_name
      this.item.specifications = item.specifications
      this.item.item_details = item.item_details

      this.getAssignItemStatuses().then(() => {
        this.item.status = item.status_id
        this.item.status_name = item.status_name
        this.item.status_last_cost = item.status_cost
        this.item.status_cost = 0.00
        this.$bvModal.show('modal-employee-item-details')
      })
    },

    onShowReturnAssignedItem (item) {
      this.state.action_status = 'RETURN'

      this.item.id = item.id
      this.item.template = item.template_id
      this.item.template_name = item.template_name
      this.item.item_code = item.item_code
      this.item.item_name = item.item_name
      this.item.serial_number = item.serial_number
      this.item.type_name = item.type_name
      this.item.category_name = item.category_name
      this.item.supplier_name = item.supplier_name
      this.item.brand_name = item.brand_name
      this.item.specifications = item.specifications
      this.item.item_details = item.item_details

      this.getAssignItemStatuses().then(() => {
        this.item.status = item.status_id
        this.item.status_name = item.status_name
        this.item.status_last_cost = item.status_cost
        this.item.status_cost = 0.00
        this.$bvModal.show('modal-employee-item-details')
      })
    },

    selectAllIncludedPeripherals () {
      this.state.busy = true
      setTimeout(() => {
        if (['ISSUE', 'RETURN'].includes(this.state.action_status)) {
          if (this.employee.item_details.length > 0) {
            this.item.item_details.forEach((itemDetail, rowIndex) => {
              const index = this.employee.item_details.findIndex(
                employeeItemDetail => employeeItemDetail.id === itemDetail.id
              )
              if (index >= 0) {
                this.$refs.tableItemDetailsOfItems.selectRow(rowIndex)
              }
            })
          }
        } else {
          this.$refs.tableItemDetailsOfItems.selectAllRows()
        }
        this.state.busy = false
      }, 1000)
    },

    onAssignItemDetail (itemDetails) {
      switch (this.state.action_status) {
        case 'ISSUE': {
          itemDetails.forEach(itemDetail => {
            const rowIndex = this.item.item_details.findIndex(
              employeeItemDetail => employeeItemDetail.id === itemDetail.id && employeeItemDetail.issuance_id !== null
            )
            if (rowIndex >= 0) {
              this.$refs.tableItemDetailsOfItems.unselectRow(rowIndex)
            }
          })
          break
        }
        case 'ASSIGN': {
          itemDetails.forEach(itemDetail => {
            const rowIndex = this.item.item_details.findIndex(
              employeeItemDetail => employeeItemDetail.id === itemDetail.id && employeeItemDetail.employee_id !== null
            )
            if (rowIndex >= 0) {
              this.$refs.tableItemDetailsOfItems.unselectRow(rowIndex)
            }
          })
          break
        }
      }

      this.selected.item_details = itemDetails
    },

    onValidateAssignEmployeeItem (btnEvnt) {
      btnEvnt.preventDefault()
      this.validateEmployeeItem(
        'Do you really want to assign the item to this employee?',
        'ASSIGN'
      )
    },

    onValidateIssueAssignedEmployeeItem (btnEvnt) {
      btnEvnt.preventDefault()
      this.validateEmployeeItem(
        'Do you really want to issue the item to this employee?',
        'ISSUE'
      )
    },

    onValidateReturnAssignedEmployeeItem (btnEvnt) {
      btnEvnt.preventDefault()
      this.validateEmployeeItem(
        'Do you really want to return the item to this employee?',
        'RETURN'
      )
    },

    async validateEmployeeItem (text, action) {
      await this.$refs.formAssignEmployeeItem.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              switch (action) {
                case 'ISSUE': {
                  return this.onIssueEmployeeItem()
                }
                case 'RETURN': {
                  return this.onReturnEmployeeItem()
                }
                default: {
                  return this.onAssignEmployeeItem()
                }
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onAssignEmployeeItem () {
      const payload = {
        id: this.employee.id,
        item: this.item.id,
        item_details: this.selected.item_details,
        status: this.item.status,
        status_cost: this.item.status_cost
      }
      return new Promise(resolve => {
        AdminEmployeeService.assign(payload).then(({ data }) => {
          this.$bvModal.hide('modal-employee-item-details')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableEmployees.refresh()
            this.$refs.tableItems.refresh()
            this.$refs.tableItemDetails.refresh()

            this.onShowEmployeeItem(data.employee)
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formAssignEmployeeItem.setErrors(error.message)
          resolve(error)
        })
      })
    },

    async onIssueEmployeeItem () {
      const payload = {
        id: this.employee.id,
        item: this.item.id,
        item_details: this.selected.item_details,
        status: this.item.status,
        status_cost: this.item.status_cost
      }
      return new Promise(resolve => {
        AdminEmployeeService.issue(payload).then(({ data }) => {
          this.$bvModal.hide('modal-employee-item-details')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableEmployees.refresh()
            this.$refs.tableItems.refresh()
            this.$refs.tableItemDetails.refresh()
            this.$refs.tableIssuances.refresh()

            this.onShowEmployeeItem(data.employee)
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formAssignEmployeeItem.setErrors(error.message)
          resolve(error)
        })
      })
    },

    async onReturnEmployeeItem () {
      const payload = {
        id: this.employee.id,
        item: this.item.id,
        item_details: this.selected.item_details,
        status: this.item.status,
        status_cost: this.item.status_cost
      }
      return new Promise(resolve => {
        AdminEmployeeService.return(payload).then(({ data }) => {
          this.$bvModal.hide('modal-employee-item-details')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.tableEmployees.refresh()
            this.$refs.tableItems.refresh()
            this.$refs.tableItemDetails.refresh()

            this.onShowEmployeeItem(data.employee)
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formAssignEmployeeItem.setErrors(error.message)
          resolve(error)
        })
      })
    },

    async issuancesProvider (ctx) {
      return await AdminEmployeeService.getIssuances(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_employee: this.employee.id
        })
      ).then(({ data }) => {
        this.tableIssuances.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    onSelectIssuance (issuances) {
      this.issuances = issuances || []
    },

    onSelectAllIssuances () {
      this.$refs.tableIssuances.selectAllRows()
    },

    onUnselectAllIssuances () {
      this.$refs.tableIssuances.clearSelected()
    },

    async onPrintIssuance (issuance) {
      this.state.busy = true
      this.selected.issuance = issuance
      this.getNotedByEmployees().finally(() => {
        const pdfDocument = this.createIssuancePDFOptions({
          info: {
            title: 'IT ASSET ISSUANCE FORM',
            author: '',
            creator: '',
            subject: ''
          },
          employee: {
            id: this.employee.id,
            employee_code: this.employee.employee_code,
            first_name: this.employee.first_name,
            middle_name: this.employee.middle_name,
            last_name: this.employee.last_name,
            unit: this.employee.unit_name,
            department: this.employee.department_name,
            position: this.employee.position_name,
            employment_status: this.employee.status,
            address: this.employee.address,
            issuance_number: issuance.issuance_number,
            issuance_date: issuance.created_at,
            items: issuance.item_histories,
            item_details: issuance.item_detail_histories
          },
          logo: this.companyLogo,
          notedByEmployees: this.list.notedByEmployees,
          withCost: this.issuanceWithCost
        })

        pdfDocument.getDataUrl(encodedPDFData => {
          this.issuancePDFData = encodedPDFData
          this.state.busy = false
        })

        this.$bvModal.show('modal-issuance-print')
      })
    },

    async onPrintAllIssuances () {
      this.state.busy = true
      this.selected.issuance = null

      this.getNotedByEmployees().finally(() => {
        const pdfDocument = this.createIssuanceAllPDFOptions({
          info: {
            title: 'IT ASSET ISSUANCE FORM',
            author: '',
            creator: '',
            subject: ''
          },
          employee: {
            id: this.employee.id,
            employee_code: this.employee.employee_code,
            first_name: this.employee.first_name,
            middle_name: this.employee.middle_name,
            last_name: this.employee.last_name,
            unit: this.employee.unit_name,
            department: this.employee.department_name,
            position: this.employee.position_name,
            employment_status: this.employee.status,
            address: this.employee.address,
            issuances: this.issuances
          },
          logo: this.companyLogo,
          notedByEmployees: this.list.notedByEmployees,
          withCost: this.issuanceWithCost
        })

        pdfDocument.getDataUrl(encodedPDFData => {
          this.issuancePDFData = encodedPDFData
          this.state.busy = false
        })

        this.$bvModal.show('modal-issuance-print')
      })
    },

    async itemDetailsProvider (ctx) {
      return await AdminEmployeeService.getItemDetails(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_employee: this.employee.id
        })
      ).then(({ data }) => {
        this.tableItemDetails.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    }
  }
}
</script>
<style>
  #modal-employee-items .modal-xl {
    width:100vw;
    max-width: none;
    min-height: 100vh;
    margin: 0
  }

  #modal-employee-items .modal-content {
    min-height: 100vh;
  }

  #modal-contract-print .modal-xl {
    width:100vw;
    max-width: none;
    min-height: 100vh;
    margin: 0
  }

  #modal-contract-print .modal-content {
    min-height: 100vh;
  }

  #modal-issuance-print .modal-xl {
    width:100vw;
    max-width: none;
    min-height: 100vh;
    margin: 0
  }

  #modal-issuance-print .modal-content {
    min-height: 100vh;
  }

  #modal-employee-issuances .modal-xl {
    width:100vw;
    max-width: none;
    min-height: 100vh;
    margin: 0
  }

  #modal-employee-issuances .modal-content {
    min-height: 100vh;
  }

  .tab-pane {
    min-height: calc(100vh - 220px) !important;
    max-height: calc(100vh - 220px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
