<template>
  <b-container
    class="mb-5"
    fluid
  >

    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="px-2">

        <b-col
          cols="12"
          md="6"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_item"
            >
              <strong>
                Item
              </strong>
            </label>
            <v-select
              id="filter_item"
              v-model="selected.filter.item"
              :options="list.filter.items"
              type="text"
              label="item_label"
              placeholder="search item"
              :disabled="state.busy || fetching.filter.items"
              :loading="fetching.filter.items"
            >
              <template #option="{ item_name, serial_number }">
                <div class="d-flex flex-column">
                  <span>{{ item_name }}</span>
                  <strong>{{ serial_number }}</strong>
                </div>
              </template>
              <template #no-options="">
                no available item
              </template>
            </v-select>
          </b-form-group>
        </b-col>

      </b-row>
      <b-row class="mb-2 px-2">

        <b-col
          cols="12"
          md="6"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tableItemDetails.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_category"
            >
              <strong>
                Category
              </strong>
            </label>
            <b-select
              id="filter_category"
              v-model="filter.category"
              :options="filterCategories"
              :disabled="state.busy || fetching.filter.categories"
              @change="onRefreshTableItemDetails"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_status"
            >
              <strong>
                Status
              </strong>
            </label>
            <b-select
              id="filter_status"
              v-model="filter.status"
              :options="filterStatuses"
              :disabled="state.busy || fetching.filter.statuses"
              @change="onFilterStatus"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="px-1"
        >
          <b-button
            type="button"
            variant="success"
            @click="onShowAddFormItemDetail"
          >
            Add Item Detail
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="tableItemDetails"
        hover
        responsive
        show-empty
        class="mt-2"
        :per-page="tableItemDetails.perPage"
        :current-page="tableItemDetails.currentPage"
        :items="myProvider"
        :fields="tableItemDetails.fields"
        :sort-by.sync="tableItemDetails.sortBy"
        :sort-desc.sync="tableItemDetails.sortDesc"
        :sort-direction="tableItemDetails.sortDirection"
        :filter="tableItemDetails.filter"
        :filter-included-fields="tableItemDetails.filterOn"
      >
        <template #cell(index)="row">
          {{ tableItemDetails.currentPage * tableItemDetails.perPage - tableItemDetails.perPage + (row.index + 1) }}
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              @click="onShowEditFormItemDetail(row.item)"
            >
              Edit
            </b-button>
            <b-button
              size="sm"
              class="mr-1"
              variant="success"
              @click="onShowItemDetailStatus(row.item)"
            >
              Status
            </b-button>
          </div>
        </template>

        <template #cell(serial_number)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(item_detail_code)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(employee_name)="row">
          <div class="text-nowrap">
            <strong>{{ row.value }}</strong>
          </div>
        </template>

        <template #cell(active)="row">
          {{ row.item.active == 1 ? "Yes" : "No" }}
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableItemDetails.perPage"
              :options="tableItemDetails.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableItemDetails.currentPage"
            :total-rows="tableItemDetails.totalRows"
            :per-page="tableItemDetails.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-item-detail"
      size="xl"
      scrollable
      no-close-on-backdrop
      :title="modalItemTitle"
      @ok="onValidateItemDetail"
    >
      <ValidationObserver
        ref="formItemDetail"
      >
        <form
          novalidate
          @submit.prevent
        >
          <b-row>

            <b-col
              cols="12"
            >
              <b-row
                v-if="state.editing"
              >

                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                    >
                      <h4>ITEM</h4>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group>
                        <label
                          for="item_serial_number"
                        >
                          <strong>
                            Serial Number
                          </strong>
                        </label>
                        <b-input
                          id="item_serial_number"
                          v-model="itemDetail.item_serial_number"
                          type="text"
                          placeholder="no assigned item"
                          autocomplete="off"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="8"
                    >
                      <b-form-group>
                        <label
                          for="item_name"
                        >
                          <strong>
                            Name
                          </strong>
                        </label>
                        <b-input
                          id="item_name"
                          v-model="itemDetail.item_name"
                          type="text"
                          placeholder="no assigned item"
                          autocomplete="off"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12 d-none d-md-block">
                  <hr>
                </b-col>
                <!--
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label
                      for="type_name"
                    >
                      <strong>
                        Type
                      </strong>
                    </label>
                    <b-input
                      id="type_name"
                      v-model="itemDetail.type_name"
                      type="text"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label
                      for="category_name"
                    >
                      <strong>
                        Category
                      </strong>
                    </label>
                    <b-input
                      id="category_name"
                      v-model="itemDetail.category_name"
                      type="text"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col> -->

              </b-row>

              <b-row>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label
                      for="item_detail_code"
                    >
                      <strong>
                        Item Detail Code
                      </strong>
                    </label>
                    <b-input
                      id="item_detail_code"
                      v-model="itemDetail.item_detail_code"
                      type="text"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="type"
                    vid="type"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="type">
                        <strong>
                          Type
                        </strong>
                      </label>
                      <b-form-select
                        id="type"
                        v-model="itemDetail.type"
                        :options="list.itemDetail.types"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy || fetching.itemDetail.types"
                        @change="getItemCategories"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- select type --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="category"
                    vid="category"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="category">
                        <strong>
                          Category
                        </strong>
                      </label>
                      <b-form-select
                        id="category"
                        v-model="itemDetail.category"
                        :options="list.itemDetail.categories"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy || fetching.itemDetail.categories"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- select category --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

              </b-row>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="serial number"
                vid="serial_number"
                rules="max:100"
              >
                <b-form-group>
                  <label for="serial_number">
                    <strong>
                      Serial Number
                    </strong>
                  </label>
                  <b-input
                    id="serial_number"
                    v-model="itemDetail.serial_number"
                    type="text"
                    autocomplete="off"
                    placeholder="enter serial number"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="8"
            >
              <ValidationProvider
                #default="{ errors }"
                name="name"
                vid="item_detail_name"
                rules="max:100"
              >
                <b-form-group>
                  <label for="item_detail_name">
                    <strong>
                      Name
                    </strong>
                  </label>
                  <b-input
                    id="item_detail_name"
                    v-model="itemDetail.item_detail_name"
                    type="text"
                    autocomplete="off"
                    placeholder="enter name"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12 d-none d-md-block">
              <hr>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                #default="{ errors }"
                name="supplier"
                vid="supplier"
                rules="required"
              >
                <b-form-group>
                  <label for="supplier">
                    <strong>
                      Supplier
                    </strong>
                  </label>
                  <v-select
                    id="supplier"
                    v-model="selected.itemDetail.supplier"
                    type="text"
                    label="supplier_name"
                    placeholder="search supplier here"
                    :class="[errors.length > 0 ? 'is-invalid' : null]"
                    :options="list.itemDetail.suppliers"
                    :disabled="state.busy || fetching.itemDetail.suppliers"
                    :loading="fetching.itemDetail.suppliers"
                  >
                    <template #option="{ supplier_name, supplier_address }">
                      <div class="d-flex flex-column">
                        <strong>{{ supplier_name }}</strong>
                        <span>{{ supplier_address }}</span>
                      </div>
                    </template>
                    <template #no-options="">
                      no available supplier
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                #default="{ errors }"
                name="brand"
                vid="brand"
                rules="required"
              >
                <b-form-group>
                  <label for="brand">
                    <strong>
                      Brand
                    </strong>
                  </label>
                  <v-select
                    id="brand"
                    v-model="selected.itemDetail.brand"
                    :options="list.itemDetail.brands"
                    type="text"
                    :class="[errors.length > 0 ? 'is-invalid' : null]"
                    label="brand_name"
                    placeholder="search brand here"
                    :disabled="state.busy || fetching.itemDetail.brands"
                    :loading="fetching.itemDetail.brands"
                  >
                    <template #option="{ brand_name }">
                      <div class="d-flex flex-column">
                        <strong>{{ brand_name }}</strong>
                      </div>
                    </template>
                    <template #no-options="">
                      no available brand
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                #default="{ errors }"
                name="purchase date"
                vid="purchase_date"
                rules="required"
              >
                <b-form-group>
                  <label for="purchase_date">
                    <strong>
                      Purchase Date
                    </strong>
                  </label>
                  <b-input
                    id="purchase_date"
                    v-model="itemDetail.purchase_date"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter purchase date"
                    autocomplete="off"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <ValidationProvider
                #default="{ errors }"
                name="original cost"
                vid="original_cost"
                rules="required"
              >
                <b-form-group>
                  <label for="original_cost">
                    <strong>
                      Cost
                    </strong>
                  </label>
                  <b-input
                    id="original_cost"
                    v-model="itemDetail.original_cost"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter cost"
                    autocomplete="off"
                    step="0.01"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="specifications"
                vid="specifications"
                rules="max:500"
              >
                <b-form-group>
                  <label for="specifications">
                    <strong>
                      Specifications
                    </strong>
                  </label>
                  <b-textarea
                    id="specifications"
                    v-model="itemDetail.specifications"
                    rows="6"
                    max-rows="12"
                    placeholder="enter specifications"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <div
                v-if="state.editing"
                class="form-group"
              >
                <b-form-checkbox
                  id="is_active"
                  v-model="itemDetail.active"
                  name="is_active"
                  value="1"
                  unchecked-value="0"
                  :disabled="state.busy"
                  switch
                >
                  Is Active?
                </b-form-checkbox>
              </div>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ state.editing ? "Update Record" : "Save Record" }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-item-detail-status"
      size="xl"
      scrollable
      no-close-on-backdrop
      title="Item Detail Status"
    >
      <ValidationObserver
        ref="formItemDetailStatus"
      >
        <form
          role="formItemDetailStatus"
          novalidate
          @submit.prevent
        >
          <b-row
            v-if="state.editing"
          >
            <b-col
              cols="12"
            >
              <h4>ITEM</h4>
            </b-col>

            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label
                      for="item_serial_number"
                    >
                      <strong>
                        Serial Number
                      </strong>
                    </label>
                    <b-input
                      id="item_serial_number"
                      v-model="itemDetailStatus.item_serial_number"
                      type="text"
                      placeholder="no assigned item"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="8"
                >
                  <b-form-group>
                    <label
                      for="item_name"
                    >
                      <strong>
                        Name
                      </strong>
                    </label>
                    <b-input
                      id="item_name"
                      v-model="itemDetailStatus.item_name"
                      type="text"
                      placeholder="no assigned item"
                      autocomplete="off"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12 d-none d-md-block">
              <hr>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="item_detail_code"
                >
                  <strong>
                    Item Detail Code
                  </strong>
                </label>
                <b-input
                  id="item_detail_code"
                  v-model="itemDetailStatus.item_detail_code"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="type_name"
                >
                  <strong>
                    Type
                  </strong>
                </label>
                <b-input
                  id="type_name"
                  v-model="itemDetailStatus.type_name"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="category_name"
                >
                  <strong>
                    Category
                  </strong>
                </label>
                <b-input
                  id="category_name"
                  v-model="itemDetailStatus.category_name"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="serial_number"
                >
                  <strong>
                    Serial Number
                  </strong>
                </label>
                <b-input
                  id="serial_number"
                  v-model="itemDetailStatus.serial_number"
                  type="text"
                  autocomplete="off"
                  placeholder="no serial number"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="8"
            >
              <b-form-group>
                <label
                  for="item_detail_name"
                >
                  <strong>
                    Name
                  </strong>
                </label>
                <b-input
                  id="item_detail_name"
                  v-model="itemDetailStatus.item_detail_name"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col cols="12 d-none d-md-block">
              <hr>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group>
                <label
                  for="original_cost"
                >
                  <strong>
                    Original Cost
                  </strong>
                </label>
                <b-input
                  id="original_cost"
                  v-model="itemDetailStatus.original_cost"
                  type="text"
                  autocomplete="off"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="status"
                vid="status"
                rules="required"
              >
                <b-form-group>
                  <label for="status">
                    <strong>
                      Status
                    </strong>
                  </label>
                  <b-form-select
                    id="status"
                    v-model="itemDetailStatus.status"
                    :options="list.itemDetail.statuses"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy || fetching.itemDetail.statuses"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- select status --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              v-if="itemDetailStatus.costable"
              cols="12"
              md="4"
            >
              <ValidationProvider
                #default="{ errors }"
                name="status cost"
                vid="status_cost"
                rules="required"
              >
                <b-form-group>
                  <label for="status_cost">
                    <strong>
                      Cost
                    </strong>
                  </label>
                  <b-input
                    id="status_cost"
                    v-model="itemDetailStatus.status_cost"
                    type="number"
                    placeholder="enter cost"
                    autocomplete="off"
                    step="0.01"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="max:500"
              >
                <b-form-group>
                  <label for="remarks">
                    <strong>
                      Remarks
                    </strong>
                  </label>
                  <b-textarea
                    id="remarks"
                    v-model="itemDetailStatus.remarks"
                    rows="6"
                    max-rows="8"
                    placeholder="enter remarks"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <div class="col-12 d-flex justify-content-between">
          <div>
            <b-button
              class="mr-1"
              variant="success"
              :disabled="state.busy"
              @click="onValidateItemDetailStatus"
            >
              Update Status
            </b-button>
            <b-button
              v-if="itemDetailStatus.item"
              variant="danger"
              :disabled="state.busy"
              @click="onValidateItemDetailStatusDetach"
            >
              Detach
            </b-button>
          </div>
          <div>
            <b-button
              variant="danger"
              :disabled="state.busy"
              @click="cancel()"
            >
              Close Window
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import {
  AdminItemDetailService,
  SharedListService
} from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminItemDetails',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Item Details'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      fetching: {
        filter: {
          categories: false,
          statuses: false,
          items: false
        },
        itemDetail: {
          items: false,
          types: false,
          categories: false,
          suppliers: false,
          brands: false,
          statuses: false
        }
      },
      filter: {
        item: 'All',
        category: 'All',
        status: 'All'
      },
      list: {
        filter: {
          categories: [],
          statuses: [],
          items: []
        },
        itemDetail: {
          items: [],
          types: [],
          categories: [],
          suppliers: [],
          brands: [],
          statuses: []
        }
      },
      selected: {
        filter: {
          item: null
        },
        itemDetail: {
          item: null,
          supplier: null,
          brand: null
        }
      },
      itemDetail: {
        id: null,
        type: null,
        type_name: null,
        category: null,
        category_name: null,
        supplier: null,
        item: null,
        item_name: null,
        item_serial_number: null,
        item_label: null,
        item_detail_code: null,
        item_detail_name: null,
        serial_number: null,
        purchase_date: null,
        brand: null,
        original_cost: 0.00,
        specifications: null,
        active: 0
      },
      itemDetailStatus: {
        id: null,
        item_name: null,
        item_serial_number: null,
        type_name: null,
        category_name: null,
        item_detail_code: null,
        item_detail_name: null,
        serial_number: null,
        original_cost: 0.00,
        status: null,
        status_cost: 0.00,
        costable: false,
        remarks: null
      },
      tableItemDetails: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          { key: 'index', class: 'text-center' },
          { key: 'actions', class: 'text-center' },
          { key: 'item.serial_number', label: 'parent serial number' },
          { key: 'item_detail_code', class: 'text-center' },
          { key: 'category_name', label: 'category' },
          { key: 'serial_number' },
          { key: 'item_detail_name', label: 'peripheral' },
          { key: 'status_name', label: 'status', class: 'text-center' },
          { key: 'employee_name', label: 'employee' },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeShortFormatter }
        ]
      }
    }
  },

  computed: {
    modalItemTitle () {
      return this.state.editing ? 'Edit Item Detail' : 'Add Item Detail'
    },

    filterCategories () {
      return [{ text: 'All', value: 'All' }].concat(this.list.filter.categories)
    },

    filterStatuses () {
      return [{ text: 'All', value: 'All' }].concat(this.list.filter.statuses)
    }
  },

  watch: {

    'selected.filter.item' (item) {
      if (item) {
        this.filter.item = item.id
      } else {
        this.filter.item = 'All'
      }

      this.onRefreshTableItemDetails()
    },

    'selected.itemDetail.supplier' (supplier) {
      this.itemDetail.supplier = supplier?.id || null
    },

    'selected.itemDetail.brand' (brand) {
      this.itemDetail.brand = brand?.id || null
    },

    'itemDetailStatus.status' (itemDetailStatus) {
      const status = this.list.itemDetail.statuses.find(status => status.value === itemDetailStatus)
      if (status) {
        this.itemDetailStatus.costable = status.costable
      } else {
        this.itemDetailStatus.costable = false
      }
    }
  },

  mounted () {
    core.index()
    this.getFilterCategories().then(() => {
      this.getFilterStatuses().then(() => {
        this.getFilterItems()
      })
    })
  },

  methods: {

    async myProvider (ctx) {
      return await AdminItemDetailService.get(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_item: this.filter.item,
          filter_category: this.filter.category,
          filter_status: this.filter.status,
          filter_attachable: 'all'
        })
      ).then(({ data }) => {
        this.tableItemDetails.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    onRefreshTableItemDetails () {
      this.$refs.tableItemDetails.refresh()
    },

    async getFilterCategories () {
      this.fetching.filter.categories = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getCategories('parent=0').then(({ data }) => {
          this.list.filter.categories = data.map(({ id, category_name }) => ({ value: id, text: category_name }))
          this.fetching.filter.categories = this.state.busy = false
          resolve(data)
        })
      })
    },

    async getFilterStatuses () {
      this.fetching.filter.statuses = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getStatuses().then(({ data }) => {
          this.list.filter.statuses = data.map(({ id, status_name }) => ({ value: id, text: status_name }))
          this.fetching.filter.statuses = this.state.busy = false
          resolve(data)
        })
      })
    },

    async getFilterItems () {
      this.selected.filter.item = null
      this.fetching.filter.items = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getItems(`status=${this.filter.status}`).then(({ data }) => {
          this.list.filter.items = data.map(
            item => ({
              id: item.id,
              category_name: item.category_name,
              serial_number: item.serial_number,
              item_name: item.item_name,
              item_label: `${item.item_name} ( ${item.serial_number} )`
            })
          )
          this.fetching.filter.items = this.state.busy = false
          resolve(true)
        })
      })
    },

    onFilterStatus () {
      this.getFilterItems().then(() => {
        this.onRefreshTableItemDetails()
      })
    },

    async getItems () {
      this.fetching.itemDetail.items = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getItems().then(({ data }) => {
          this.list.itemDetail.items = data.map(
            item => ({
              id: item.id,
              category_name: item.category_name,
              serial_number: item.serial_number,
              item_label: `${item.item_name} ( ${item.serial_number} )`
            })
          )
          this.fetching.itemDetail.items = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getItemTypes () {
      this.fetching.itemDetail.types = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getTypes().then(({ data }) => {
          this.list.itemDetail.types = data.map(({ id, type_name }) => ({
            text: type_name,
            value: id
          }))
          this.fetching.itemDetail.types = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getItemCategories (type) {
      this.itemDetail.category = null
      this.list.itemDetail.categories = []

      if (this.$refs.formItemDetail) {
        this.$refs.formItemDetail.reset()
      }

      this.fetching.itemDetail.categories = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getCategories(`type=${type}&parent=0`).then(({ data }) => {
          this.list.itemDetail.categories = data.map(({ id, category_name }) => ({
            text: category_name,
            value: id
          }))
          this.fetching.itemDetail.categories = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getItemSuppliers () {
      this.fetching.itemDetail.suppliers = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getSuppliers().then(({ data }) => {
          this.list.itemDetail.suppliers = data.map(({ id, supplier_name, supplier_address }) => ({ id, supplier_name, supplier_address }))
          this.fetching.itemDetail.suppliers = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getItemBrands () {
      this.fetching.itemDetail.brands = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getBrands().then(({ data }) => {
          this.list.itemDetail.brands = data.map(({ id, brand_name }) => ({ id, brand_name }))
          this.fetching.itemDetail.brands = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getItemStatuses () {
      this.fetching.itemDetail.statuses = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getStatuses().then(({ data }) => {
          this.list.itemDetail.statuses = data.map(({ id, status_name, costable }) => ({
            text: status_name,
            value: id,
            costable: costable
          }))
          this.fetching.itemDetail.statuses = this.state.busy = false
          resolve(true)
        })
      })
    },

    onShowAddFormItemDetail () {
      this.state.editing = false

      this.itemDetail.item = null
      this.itemDetail.item_name = null
      this.itemDetail.item_serial_number = null
      this.itemDetail.id = null
      this.itemDetail.item_detail_code = null
      this.itemDetail.item_detail_name = null
      this.itemDetail.type = null
      this.itemDetail.type_name = null
      this.itemDetail.category = null
      this.itemDetail.category_name = null
      this.itemDetail.supplier = null
      this.itemDetail.status = null
      this.itemDetail.purchase_date = null
      this.itemDetail.serial_number = null
      this.itemDetail.brand = null
      this.itemDetail.original_cost = 0.00
      this.itemDetail.specifications = null
      this.itemDetail.active = 0

      this.selected.itemDetail.item = null
      this.selected.itemDetail.supplier = null
      this.selected.itemDetail.brand = null

      this.getItemTypes().then(() => {
        this.getItemSuppliers().then(() => {
          this.getItemBrands().then(() => {
            this.$bvModal.show('modal-item-detail')
          })
        })
      })
    },

    onShowEditFormItemDetail (itemDetail) {
      this.state.editing = true

      this.itemDetail.item = null
      this.itemDetail.item_label = null
      this.itemDetail.item_name = null
      this.itemDetail.item_serial_number = null

      if (itemDetail.item) {
        this.itemDetail.item = itemDetail.item.id
        this.itemDetail.item_name = itemDetail.item.item_name
        this.itemDetail.item_serial_number = itemDetail.item.serial_number
      }

      this.itemDetail.id = itemDetail.id
      this.itemDetail.item_detail_code = itemDetail.item_detail_code
      this.itemDetail.item_detail_name = itemDetail.item_detail_name
      this.itemDetail.serial_number = itemDetail.serial_number
      this.itemDetail.purchase_date = itemDetail.purchase_date
      this.itemDetail.original_cost = itemDetail.original_cost
      this.itemDetail.specifications = itemDetail.specifications
      this.itemDetail.active = itemDetail.active

      this.selected.itemDetail.supplier = null
      this.selected.itemDetail.brand = null

      this.getItemTypes().then(() => {
        this.itemDetail.type = itemDetail.type_id
        this.itemDetail.type_name = itemDetail.type_name

        this.getItemCategories().then(() => {
          this.itemDetail.category = itemDetail.category_id
          this.itemDetail.category_name = itemDetail.category_name
        })
      })

      this.getItemSuppliers().then(() => {
        this.itemDetail.supplier = itemDetail.supplier_id
        this.selected.itemDetail.supplier = {
          id: itemDetail.supplier.id,
          supplier_name: itemDetail.supplier.supplier_name,
          supplier_address: itemDetail.supplier.supplier_address
        }

        this.getItemBrands().then(() => {
          this.itemDetail.brand = itemDetail.brand_id
          this.selected.itemDetail.brand = {
            id: itemDetail.brand.id,
            brand_name: itemDetail.brand.brand_name
          }
          this.$bvModal.show('modal-item-detail')
        })
      })
    },

    async onValidateItemDetail (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formItemDetail.validate().then(async success => {
        if (success) {
          let text = 'Do you really want to create item detail?'

          if (this.state.editing) {
            text = 'Do you really want to save changes?'
          }

          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: text,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.onPutItemDetail()
              } else {
                return this.onPostItemDetail()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onPostItemDetail () {
      return new Promise(resolve => {
        AdminItemDetailService.post(this.itemDetail).then(({ data }) => {
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.itemDetail.serial_number = null
            this.$refs.tableItemDetails.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formItemDetail.setErrors(error.message)
          resolve(error)
        })
      })
    },

    async onPutItemDetail () {
      return new Promise(resolve => {
        AdminItemDetailService.put(this.itemDetail).then(({ data }) => {
          this.$bvModal.hide('modal-item-detail')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableItemDetails.localItems, { id: data.itemDetail.id })
            row.id = data.itemDetail.id
            row.item_detail_code = data.itemDetail.item_detail_code
            row.item_detail_name = data.itemDetail.item_detail_name
            row.category = data.itemDetail.category
            row.category_id = data.itemDetail.category_id
            row.category_name = data.itemDetail.category_name
            row.supplier = data.itemDetail.supplier
            row.supplier_id = data.itemDetail.supplier_id
            row.supplier_name = data.itemDetail.supplier_name
            row.brand = data.itemDetail.brand
            row.brand_id = data.itemDetail.brand_id
            row.brand_name = data.itemDetail.brand_name
            row.serial_number = data.itemDetail.serial_number
            row.purchase_date = data.itemDetail.purchase_date
            row.original_cost = data.itemDetail.original_cost
            row.specifications = data.itemDetail.specifications
            row.status = data.itemDetail.status
            row.status_id = data.itemDetail.status_id
            row.status_name = data.itemDetail.status_name
            row.status_cost = data.itemDetail.status_cost
            row.remarks = data.itemDetail.remarks
            row.active = data.itemDetail.active
            row.updated_at = data.itemDetail.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formItemDetail.setErrors(error.message)
          resolve(error)
        })
      })
    },

    onShowItemDetailStatus (itemDetail) {
      this.state.editing = true

      this.itemDetailStatus.id = itemDetail.id
      this.itemDetailStatus.item_detail_code = itemDetail.item_detail_code
      this.itemDetailStatus.item_detail_name = itemDetail.item_detail_name
      this.itemDetailStatus.serial_number = itemDetail.serial_number
      this.itemDetailStatus.original_cost = itemDetail.original_cost

      this.itemDetailStatus.item = null
      this.itemDetailStatus.item_label = null
      this.itemDetailStatus.item_name = null
      this.itemDetailStatus.item_serial_number = null

      if (itemDetail.item) {
        this.itemDetailStatus.item = itemDetail.item.id
        this.itemDetailStatus.item_name = itemDetail.item.item_name
        this.itemDetailStatus.item_serial_number = itemDetail.item.serial_number
      }

      this.itemDetailStatus.type = itemDetail.type_id
      this.itemDetailStatus.type_name = itemDetail.type_name
      this.itemDetailStatus.category = itemDetail.category_id
      this.itemDetailStatus.category_name = itemDetail.category_name

      this.itemDetailStatus.status = itemDetail.status_id
      this.itemDetailStatus.status_cost = 0.00
      this.itemDetailStatus.remarks = itemDetail.remarks

      this.getItemStatuses().then(() => {
        this.$bvModal.show('modal-item-detail-status')
      })
    },

    async onValidateItemDetailStatus () {
      await this.$refs.formItemDetailStatus.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to save changes?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              return this.onPutItemDetailStatus()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onPutItemDetailStatus () {
      return new Promise(resolve => {
        AdminItemDetailService.status(this.itemDetailStatus).then(({ data }) => {
          this.$bvModal.hide('modal-item-detail-status')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableItemDetails.localItems, { id: data.itemDetail.id })
            row.id = data.itemDetail.id
            row.item_detail_code = data.itemDetail.item_detail_code
            row.item_detail_name = data.itemDetail.item_detail_name
            row.category = data.itemDetail.category
            row.category_id = data.itemDetail.category_id
            row.category_name = data.itemDetail.category_name
            row.supplier = data.itemDetail.supplier
            row.supplier_id = data.itemDetail.supplier_id
            row.supplier_name = data.itemDetail.supplier_name
            row.brand = data.itemDetail.brand
            row.brand_id = data.itemDetail.brand_id
            row.brand_name = data.itemDetail.brand_name
            row.serial_number = data.itemDetail.serial_number
            row.purchase_date = data.itemDetail.purchase_date
            row.original_cost = data.itemDetail.original_cost
            row.specifications = data.itemDetail.specifications
            row.status = data.itemDetail.status
            row.status_id = data.itemDetail.status_id
            row.status_name = data.itemDetail.status_name
            row.status_cost = data.itemDetail.status_cost
            row.remarks = data.itemDetail.remarks
            row.active = data.itemDetail.active
            row.updated_at = data.itemDetail.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formItemDetailStatus.setErrors(error.message)
          resolve(error)
        })
      })
    },

    async onValidateItemDetailStatusDetach () {
      await this.$refs.formItemDetailStatus.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to save changes?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.state.busy = true
              return this.onPutItemDetailStatusDetach()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onPutItemDetailStatusDetach () {
      return new Promise(resolve => {
        AdminItemDetailService.detach(this.itemDetailStatus).then(({ data }) => {
          this.$bvModal.hide('modal-item-detail-status')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.tableItemDetails.localItems, { id: data.itemDetail.id })
            row.id = data.itemDetail.id
            row.category = data.itemDetail.category
            row.category_id = data.itemDetail.category_id
            row.category_name = data.itemDetail.category_name
            row.supplier = data.itemDetail.supplier
            row.supplier_id = data.itemDetail.supplier_id
            row.supplier_name = data.itemDetail.supplier_name
            row.status = data.itemDetail.status
            row.status_id = data.itemDetail.status_id
            row.status_name = data.itemDetail.status_name
            row.status_cost = data.itemDetail.status_cost
            row.purchase_date = data.itemDetail.purchase_date
            row.serial_number = data.itemDetail.serial_number
            row.brand = data.itemDetail.brand
            row.original_cost = data.itemDetail.original_cost
            row.specifications = data.itemDetail.specifications
            row.remarks = data.itemDetail.remarks
            row.active = data.itemDetail.active
            row.updated_at = data.itemDetail.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          this.$refs.formItemDetailStatus.setErrors(error.message)
          resolve(error)
        })
      })
    }
  }
}
</script>
