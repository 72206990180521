<template>
  <b-container
    class="mb-5"
    fluid
  >

    <div class="d-flex justify-content-end mb-3">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2 px-2">

        <b-col
          cols="12"
          md="6"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_item"
            >
              <strong>
                Item
              </strong>
            </label>
            <v-select
              id="filter_item"
              v-model="selected.filter.item"
              :options="list.filter.items"
              type="text"
              label="item_label"
              placeholder="search item"
              :disabled="state.busy || fetching.filter.items"
              :loading="fetching.filter.items"
            >
              <template #option="{ item_name, serial_number }">
                <div class="d-flex flex-column">
                  <span>{{ item_name }}</span>
                  <strong>{{ serial_number }}</strong>
                </div>
              </template>
              <template #no-options="">
                no available item
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_employee"
            >
              <strong>
                Employee
              </strong>
            </label>
            <v-select
              id="filter_employee"
              v-model="selected.filter.employee"
              :options="list.filter.employees"
              type="text"
              label="employee_name"
              placeholder="search employee"
              :disabled="state.busy || fetching.filter.employees"
              :loading="fetching.filter.employees"
            >
              <template #option="{ employee_name, employee_code }">
                <div class="d-flex flex-column">
                  <span>{{ employee_name }}</span>
                  <strong>{{ employee_code }}</strong>
                </div>
              </template>
              <template #no-options="">
                no available employee
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_search"
            >
              <strong>
                Search
              </strong>
            </label>
            <b-input
              id="filter_search"
              v-model="tableItemDetails.filter"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_category"
            >
              <strong>
                Category
              </strong>
            </label>
            <b-select
              id="filter_category"
              v-model="filter.category"
              :options="filterCategories"
              :disabled="state.busy || fetching.filter.categories"
              @change="onRefreshTableItemDetails"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group>
            <label
              for="filter_action"
            >
              <strong>
                Action
              </strong>
            </label>
            <b-select
              id="filter_action"
              v-model="filter.action"
              :options="filterActions"
              :disabled="state.busy"
              @change="onRefreshTableItemDetails"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="tableItemDetails"
        hover
        responsive
        show-empty
        striped
        bordered
        class="mt-2"
        :per-page="tableItemDetails.perPage"
        :current-page="tableItemDetails.currentPage"
        :items="myProvider"
        :fields="tableItemDetails.fields"
        :sort-by.sync="tableItemDetails.sortBy"
        :sort-desc.sync="tableItemDetails.sortDesc"
        :sort-direction="tableItemDetails.sortDirection"
        :filter="tableItemDetails.filter"
        :filter-included-fields="tableItemDetails.filterOn"
      >
        <template #cell(index)="row">
          <div
            class="text-nowrap d-flex justify-content-center align-items-center"
            style="height: 115px;"
          >
            {{ tableItemDetails.currentPage * tableItemDetails.perPage - tableItemDetails.perPage + (row.index + 1) }}
          </div>
        </template>

        <template #cell(specifications)="row">
          <div class="text-nowrap text-center">
            <b-button
              size="sm"
              :variant="row.detailsShowing ? 'danger' : 'success' "
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? 'Hide' : 'Show' }}
            </b-button>
          </div>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col
                cols="12"
                md="6"
              >
                <b-col
                  cols="12"
                >
                  <strong>Specifications:</strong>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-textarea
                    v-model="row.item.specifications"
                    rows="8"
                    max-rows="12"
                    class="border-0"
                    readonly
                  />
                </b-col>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-col
                  cols="12"
                >
                  <strong>Remarks:</strong>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-textarea
                    v-model="row.item.remarks"
                    rows="8"
                    max-rows="12"
                    class="border-0"
                    readonly
                  />
                </b-col>
              </b-col>
            </b-row>
          </b-card>
        </template>

        <template #cell(active)="row">
          <div
            class="text-nowrap d-flex justify-content-center align-items-center"
            style="height: 115px;"
          >
            {{ row.item.active == 1 ? "Yes" : "No" }}
          </div>
        </template>

        <template #cell()="row">
          <div class="text-nowrap">
            {{ row.value }}
          </div>
        </template>

        <template #cell(updated_by_name)="row">
          <b-row
            class="text-nowrap py-2 my-0 px-2"
            style="min-width: 300px;"
          >
            <b-col
              cols="12"
              class="mb-1"
            >
              {{ dateTimeLongFormatter(row.item.updated_at) }}
            </b-col>
            <b-col
              cols="12"
            >
              &nbsp;
            </b-col>
            <b-col
              cols="12"
              :class="`my-1 text-right font-weight-bolder text-${itemDetailAction(row.item.action)}`"
            >
              {{ row.item.action }}
            </b-col>
            <b-col
              cols="12"
              class="px-0"
            >
              <hr class="p-0 m-0">
            </b-col>
            <b-col
              cols="12"
              class="my-1 text-right"
            >
              {{ row.item.updated_by_name }}
            </b-col>
          </b-row>
        </template>

        <template #cell(item_name)="row">
          <b-row
            v-if="row.item.item"
            class="text-nowrap py-2 my-0 px-2"
            style="min-width: 300px;"
          >
            <b-col
              cols="12"
              class="mb-1"
            >
              {{ row.item.item.item_name }}
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              {{ row.item.item.brand_name }}
            </b-col>
            <b-col
              cols="12"
              class="mb-1 text-right"
            >
              <strong class="text-success">{{ row.item.item.category_name }}</strong>
            </b-col>
            <b-col
              cols="12"
              class="px-0"
            >
              <hr class="p-0 m-0">
            </b-col>
            <b-col
              cols="12"
              class="my-1 text-right"
            >
              <strong>{{ row.item.item.serial_number }}</strong>
            </b-col>
          </b-row>
        </template>

        <template #cell(item_detail_name)="row">
          <b-row
            v-if="row.item"
            class="text-nowrap py-2 my-0 px-2"
            style="min-width: 350px;"
          >
            <b-col
              cols="12"
              class="mb-1"
            >
              {{ row.item.item_detail_name }}
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              {{ row.item.brand_name }}
            </b-col>
            <b-col
              cols="12"
              class="mb-1 text-right"
            >
              <strong class="text-success">{{ row.item.category_name }}</strong>
            </b-col>
            <b-col
              cols="12"
              class="px-0"
            >
              <hr class="p-0 m-0">
            </b-col>
            <b-col
              cols="12"
              class="my-1 text-right"
            >
              <strong>{{ row.item.serial_number }}</strong>
            </b-col>
            <b-col
              cols="12"
              class="mb-1 text-right"
            >
              <i>{{ row.item.item_detail_code }}</i>
            </b-col>
          </b-row>
        </template>

        <template #cell(swap_item_detail_id)="row">
          <div
            style="min-width: 250px"
          >
            <b-row
              v-if="row.item.swapped_item_detail"
              class="text-nowrap py-0 my-0 px-1"
            >
              <b-col
                cols="12"
                class="mb-1"
              >
                <b-row>
                  <b-col
                    cols="6"
                  >
                    {{ row.item.swapped_item_detail.type_name }}
                  </b-col>
                  <b-col
                    cols="6"
                    class="text-right"
                  >
                    <strong class="text-success">{{ row.item.swapped_item_detail.category_name }}</strong>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mb-1"
              >
                {{ row.item.swapped_item_detail.brand_name }}
              </b-col>
              <b-col
                cols="12"
                class="px-0"
              >
                <hr class="p-0 m-0">
              </b-col>
              <b-col
                cols="12"
                class="mb-1 text-right"
              >
                {{ row.item.swapped_item_detail.item_detail_name }}
              </b-col>
              <b-col
                cols="12"
                class="text-right"
              >
                <strong>{{ row.item.swapped_item_detail.serial_number }}</strong>
              </b-col>
            </b-row>
          </div>
        </template>

        <template #cell(issuance_number)="row">
          <div
            class="text-nowrap text-center"
            style="margin-top: 55px;"
          >
            {{ row.value }}
          </div>
        </template>

        <template #cell(employee_id)="row">
          <div
            style="min-width: 300px;"
          >
            <b-row
              v-if="row.item.employee"
              class="text-nowrap py-2 my-0 px-2"
            >
              <b-col
                cols="12"
                class="mb-1"
              >
                {{ row.item.employee.first_name }} {{ row.item.employee.last_name }}
              </b-col>
              <b-col
                cols="12"
                class="mb-1"
              >
                {{ row.item.employee.position_name }}
              </b-col>
              <b-col
                cols="12"
                class="mb-1 text-right font-weight-bolder"
              >
                {{ row.item.employee.status }}
              </b-col>
              <b-col
                cols="12"
                class="px-0"
              >
                <hr class="p-0 m-0">
              </b-col>
              <b-col
                cols="12"
                class="my-1 text-right"
              >
                <strong>{{ row.item.employee.employee_code }}</strong>
              </b-col>
            </b-row>
          </div>
        </template>

        <template #cell(status_name)="row">
          <b-row
            class="text-nowrap py-2 my-0 px-2"
            style="min-width: 300px;"
          >
            <b-col
              cols="12"
              class="mb-1"
            >
              {{ dateLongFormatter(row.item.purchase_date) }}
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              {{ row.item.supplier_name }}
            </b-col>
            <b-col
              cols="12"
              class="mb-1 text-right"
            >
              <strong>₱ {{ row.item.original_cost }}</strong>
            </b-col>
            <b-col
              cols="12"
              class="px-0"
            >
              <hr class="p-0 m-0">
            </b-col>
            <b-col
              cols="12"
              class="py-2"
            >
              <b-row>
                <b-col
                  cols="6"
                  class="mb-1"
                >
                  {{ row.item.status_name }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  <strong>₱ {{ row.item.status_cost }}</strong>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableItemDetails.perPage"
              :options="tableItemDetails.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableItemDetails.currentPage"
            :total-rows="tableItemDetails.totalRows"
            :per-page="tableItemDetails.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import {
  AdminHistoryService,
  SharedListService
} from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminItemDetailHistories',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Item Details'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      fetching: {
        filter: {
          items: false,
          categories: false
        }
      },
      filter: {
        item: 'All',
        category: 'All',
        action: 'All'
      },
      list: {
        filter: {
          categories: [],
          items: [],
          actions: [
            {
              value: 'Created',
              text: 'Created'
            },
            {
              value: 'Updated',
              text: 'Updated'
            },
            {
              value: 'Attached',
              text: 'Attached'
            },
            {
              value: 'Detached',
              text: 'Detached'
            },
            {
              value: 'Assigned',
              text: 'Assigned'
            },
            {
              value: 'Issued',
              text: 'Issued'
            },
            {
              value: 'Returned',
              text: 'Returned'
            }
          ]
        }
      },
      selected: {
        filter: {
          item: null
        }
      },
      tableItemDetails: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [
          'index',
          { key: 'specifications' },
          { key: 'updated_by_name', label: 'action by' },
          { key: 'item_name', label: 'item' },
          { key: 'item_detail_name', label: 'peripheral' },
          // { key: 'swap_item_detail_id', label: 'swapped peripheral' },
          { key: 'issuance_number' },
          { key: 'employee_id', label: 'employee' },
          { key: 'status_name', label: 'supplier / status' },
          { key: 'active' }
        ]
      }
    }
  },

  computed: {
    filterCategories () {
      return [{ text: 'All', value: 'All' }].concat(this.list.filter.categories)
    },

    filterActions () {
      return [{ text: 'All', value: 'All' }].concat(this.list.filter.actions)
    }
  },

  watch: {

    'selected.filter.item' (item) {
      if (item) {
        this.filter.item = item.id
      } else {
        this.filter.item = 'All'
      }

      this.onRefreshTableItemDetails()
    },

    'selected.filter.employee' (employee) {
      if (employee) {
        this.filter.employee = employee.id
      } else {
        this.filter.employee = 'All'
      }

      this.onRefreshTableItemDetails()
    }

  },

  mounted () {
    core.index()
    this.getFilterCategories().then(() => {
      this.getFilterItems().then(() => {
        this.getFilterEmployees()
      })
    })
  },

  methods: {

    async myProvider (ctx) {
      return await AdminHistoryService.getItemDetailHistories(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_employee: this.filter.employee,
          filter_item: this.filter.item,
          filter_category: this.filter.category,
          filter_action: this.filter.action
        })
      ).then(({ data }) => {
        this.tableItemDetails.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        return []
      })
    },

    async getFilterEmployees () {
      this.fetching.filter.employees = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getEmployees().then(({ data }) => {
          this.list.filter.employees = data.map(({ id, employee_code, employee_name }) => ({ id, employee_code, employee_name }))
          this.fetching.filter.employees = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getFilterCategories () {
      this.fetching.filter.categories = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getCategories('parent=0').then(({ data }) => {
          this.list.filter.categories = data.map(({ id, category_name }) => ({ value: id, text: category_name }))
          this.fetching.filter.categories = this.state.busy = false
          resolve(true)
        })
      })
    },

    async getFilterItems (type) {
      this.selected.filter.item = null
      this.fetching.filter.items = this.state.busy = true
      return new Promise(resolve => {
        SharedListService.getItems(`type=${type}`).then(({ data }) => {
          this.list.filter.items = data.map(
            item => ({
              id: item.id,
              category_name: item.category_name,
              serial_number: item.serial_number,
              item_name: item.item_name,
              item_label: `${item.item_name} ( ${item.serial_number} )`
            })
          )
          this.fetching.filter.items = this.state.busy = false
          resolve(true)
        })
      })
    },

    onRefreshTableItemDetails () {
      this.$refs.tableItemDetails.refresh()
    }
  }
}
</script>
